import { NgModule } from '@angular/core';
import { QRCodeModule } from 'angularx-qrcode';
import { CardComponentModule, SharedModule } from 'lib-trend-core';
import { ChannelEvoGOComponent } from './channel-evo-go/channel-evo-go.component';
import { ChannelEvoComponent } from './channel-evo/channel-evo.component';
import { ChannelFormComponent } from './channel-form/channel-form.component';
import { ChannelRoutingModule } from './channel-routing.module';
import { ChannelViewComponent } from './channel-view/channel-view.component';
import { ChannelComponent } from './channel.component';

@NgModule({
  imports: [
    SharedModule,
    CardComponentModule,
    QRCodeModule,
    ChannelRoutingModule,
  ],
  declarations: [
    ChannelComponent,
    ChannelFormComponent,
    ChannelEvoComponent,
    ChannelEvoGOComponent,
    ChannelViewComponent,
  ],
  exports: [
    ChannelComponent,
    ChannelFormComponent,
    ChannelEvoComponent,
    ChannelEvoGOComponent,
    ChannelViewComponent,
  ],
  providers: [],
  schemas: []
})
export class ChannelModule { }
