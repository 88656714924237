import { Component, Injector } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractComponent, Channel, ChannelService, ChannelTypeEnum } from 'lib-trend-core';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'channel-form-component',
  templateUrl: './channel-form.component.html',
  styleUrls: ['./channel-form.component.scss']
})
export class ChannelFormComponent extends AbstractComponent {

  channel: Channel;

  cloudAPI: ChannelTypeEnum = ChannelTypeEnum.CLOUD_API;
  evolutionAPI: ChannelTypeEnum = ChannelTypeEnum.EVOLUTION_API;
  evolutionGOAPI: ChannelTypeEnum = ChannelTypeEnum.EVOLUTION_GO_API;

  constructor(
    injector: Injector,
    public channelService: ChannelService,
  ) {
    super(injector);
    this.channel = {
      metadata: {},
    } as Channel;
    this.createForm();
  }

  ngOnInit() {
    this.isNew = true;
    const idChannel = super.getParam('idChannel');
    if (!!idChannel) {
      this.isNew = false;
      this.channelService.getById(idChannel)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (channel: Channel) => {
            this.channel = channel;
            this.formGroup.patchValue(this.channel);
          },
          error: (err) => this.alertService.error(err.error.message)
        });
    }
  }

  private createForm(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(this.channel.name, [Validators.required]),
      type: new FormControl(this.channel.type, [Validators.required]),
      metadata: new FormGroup({
        token: new FormControl(this.channel.metadata['token']),
        phoneNumberID: new FormControl(this.channel.metadata['phoneNumberID']),
        whatsAppBusinessAccountID: new FormControl(this.channel.metadata['whatsAppBusinessAccountID']),
      }),
    });
    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.channel, value);
    });
  }

  close(): void {
  }

  save() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      return;
    }

    if (this.isNew) {
      this.channelService.create(this.channel).subscribe({
        next: (value) => {
          this.alertService.success('Canal salvo com sucesso.');
          this.goBack();
        },
        error: (err) => {
          this.alertService.error(err.error.message);
        },
        complete: () => {
        },
      });
    } else {
      this.channelService.update(this.channel._id, this.channel).subscribe({
        next: (value) => {
          this.alertService.success('Canal salvo com sucesso.');
          this.goBack();
        },
        error: (err) => {
          this.alertService.error(err.error.message);
        },
        complete: () => {
        },
      });
    }
  }

}
