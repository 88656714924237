<div class="flex flex-1 flex-col px-5">
  <div class="container-full flex flex-col justify-content-center">
    <h1 class="font-black text-[20px] text-black mt-2.5">{{isNew ? 'Adicionar' : 'Alterar'}} Canal</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">
      {{ isNew ? 'Preencha os dados e crie um novo canal' :
      'Preencha os dados para alterar seu canal existente'}}</h6>
  </div>
  <hr />
  <form [formGroup]="formGroup">
    <div class="flex flex-col mt-[15px]">
      <label class="font-semibold text-black">Selecione um canal *</label>
      <mat-select class="input-field" formControlName="type" placeholder="Selecione">
        <mat-option [value]="cloudAPI">Cloud API</mat-option>
        <mat-option [value]="evolutionAPI">Business</mat-option>
        <mat-option [value]="evolutionGOAPI">Business PRO</mat-option>
      </mat-select>
      <field-error-component [field]="'type'" [label]="'Canal'" [formGroup]="formGroup"></field-error-component>
    </div>
    @if (formGroup.get('type')?.value) {
    <div class="flex flex-col">
      <label class="font-semibold text-black mt-2">Nome *</label>
      <input maxlength="100" formControlName="name" class="input-field" placeholder="Digite o nome do seu canal...">
      <field-error-component [field]="'name'" [label]="'Nome'" [formGroup]="formGroup"></field-error-component>
    </div>
    @if (formGroup.get('type')?.value === 'CLOUD_API') {
    <div class="flex flex-col mt-[15px]" formGroupName="metadata">
      <div class="flex flex-col">
        <label class="font-semibold text-black">Token</label>
        <input formControlName="token" class="input-field" placeholder="Digite o seu token...">
      </div>
      <div class="flex w-full mt-[15px] gap-2">
        <div class="flex w-[49%] flex-col">
          <label class="font-semibold text-black">Phone Number ID</label>
          <input maxlength="100" formControlName="phoneNumberID" class="input-field"
            placeholder="Digite o Phone Number ID...">
        </div>
        <div class="flex w-[49%] flex-col">
          <label class="font-semibold text-black">WhatsApp Business Account ID</label>
          <input maxlength="100" formControlName="whatsAppBusinessAccountID" class="input-field"
            placeholder="Digite o WhatsApp Business ID...">
        </div>
      </div>
    </div>
    }
    }
  </form>

  <div class="flex flex-1 border-t-2 border-gray-200 justify-end items-center w-full gap-4 mt-3 mb-4">
    <button
      class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
      (click)="save()" [disabled]="!formGroup.valid" *ngIf="isAdmin()">{{ isNew ? 'Salvar' : 'Alterar' }}</button>
  </div>

</div>
<!-- <div class="flex justify-between items-center w-full gap-2 mt-[30px]">
  <button
    class="h-[45px] px-14 flex cursor-pointer justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[47%]"
    (click)="close()">Fechar</button>
  <button
    class="h-[45px] px-14 flex cursor-pointer justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[47%]"
    (click)="save()" [disabled]="!formGroup.valid">{{ isNew ? 'Salvar' : 'Alterar' }}</button>
</div> -->