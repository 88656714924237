<div class="flex flex-1 flex-col px-5">

  <div class="mb-4">
    <h1 class="font-black text-[20px] text-black">Canal - {{channel?.name}}</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Visualização do Canal</h6>
    <hr />
  </div>

  <channel-evo-component *ngIf="!!channel && channel?.type === 'EVOLUTION_API'" [channel]="channel"></channel-evo-component>
  <channel-evo-go-component *ngIf="!!channel && channel?.type === 'EVOLUTION_GO_API'" [channel]="channel"></channel-evo-go-component>

</div>