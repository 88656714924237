import { AfterContentInit, Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractComponent, Channel, ChannelService } from 'lib-trend-core';
import { BehaviorSubject, concatMap, map, mergeMap, of, takeUntil, tap, timer } from 'rxjs';

@Component({
  selector: 'channel-evo-go-component',
  templateUrl: './channel-evo-go.component.html',
  styleUrls: ['./channel-evo-go.component.scss']
})
export class ChannelEvoGOComponent extends AbstractComponent implements OnInit, AfterContentInit {

  @Input('channel') channel: Channel;

  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();

  INSTANCE_CODE_DATA: string;
  connected: boolean = false;
  countContacts: number = 0;
  countChats: number = 0;
  countMessages: number = 0;

  constructor(
    injector: Injector,
    public channelService: ChannelService,
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  ngAfterContentInit(): void {
    this.init();
  }

  // Method for fetch base64 and with interval of 20 seconds
  private init(): void {
    this.loadingSpinnerSubject.next(true);
    timer(0, 5000).pipe(
      mergeMap(() => this.channelService.syncInstanceByChannel(this.channel._id)),
      concatMap((channel) => {
        this.channel = channel;
        // this.countContacts = this.channel.metadata['_count']['Contact'];
        // this.countChats = this.channel.metadata['_count']['Chat'];
        // this.countMessages = this.channel.metadata['_count']['Message'];
        this.connected = this.channel.metadata['connected'];
        if (this.connected) {
          return of(null);
        } else {
          return this.channelService.getInstanceConnect(this.channel._id)
            .pipe(
              map((value) => {
                this.INSTANCE_CODE_DATA = value['data']['Code'];
              }),
              takeUntil(this.destroy$),
            );
        }
      }),
      tap(() => this.loadingSpinnerSubject.next(false)),
      takeUntil(this.destroy$),
    ).subscribe();
  }

  sync(): void {
    this.loadingSpinnerSubject.next(true);
    this.channelService.syncInstanceByChannel(this.channel._id).subscribe({
      next: (channel: Channel) => this.channel = channel,
      error: (err) => this.alertService.error(err.error.message),
      complete: (() => this.loadingSpinnerSubject.next(true))
    });
  }

  syncContacts(): void {
    this.loadingSpinnerSubject.next(true);
    this.channelService.syncContactsByChannel(this.channel._id).subscribe({
      next: (channel: Channel) => {
        this.channel = channel;
        this.alertService.success('Contatos sincronizados com sucesso!');
      },
      error: (err) => this.alertService.error(err.error.message),
      complete: (() => this.loadingSpinnerSubject.next(true))
    });
  }


  restart(): void {
    this.loadingSpinnerSubject.next(true);
    this.channelService.restartInstanceByChannel(this.channel._id).subscribe({
      next: (channel: Channel) => this.channel = channel,
      error: (err) => this.alertService.error(err.error.message),
      complete: (() => this.loadingSpinnerSubject.next(true))
    });
  }

  disconnect(): void {
    this.loadingSpinnerSubject.next(true);
    this.channelService.disconnectInstanceByChannel(this.channel._id).subscribe({
      next: (channel: Channel) => this.channel = channel,
      error: (err) => this.alertService.error(err.error.message),
      complete: (() => this.loadingSpinnerSubject.next(true))
    });
  }

}
