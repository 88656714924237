<div>
  <!-- <div class="container-full flex flex-col justify-content-center">
    <h1 class="font-black text-[20px] text-black mt-2.5">Status do Canal</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">{{connected ?
      'Abaixo está o status do seu canal conectado' :
      'Leia o QrCode abaixo para conectar seu canal'}}</h6>
  </div> -->
  <div class="flex items-center justify-center loading-container">
    <mat-card class="p-3">
      <div class="flex flex-col items-center">
        <!-- <h6 class="text-zinc-400 font-light text-sm my-2">{{channel.metadata['name']}}</h6> -->
        <h6 class="text-black">{{channel?.name}}</h6>
        <span class="text-[#4213F6] font-bold">{{connected ? 'Conectado' : 'Desconectado'}}</span>
      </div>

      <div *ngIf="!connected">
        <qrcode *ngIf="INSTANCE_CODE_DATA" [qrdata]="INSTANCE_CODE_DATA"
          [imageSrc]="'../../../../assets/images/logo-icon-sidebar.png'" [imageWidth]="50" [imageHeight]="50"
          [width]="256">
        </qrcode>
      </div>

      <div class="w-full" *ngIf="connected">
        <mat-grid-list cols="3" rowHeight="2:1">
          <mat-grid-tile>Contatos</mat-grid-tile>
          <mat-grid-tile>Chats</mat-grid-tile>
          <mat-grid-tile class="!w-[90px]">Mensagens</mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="3" rowHeight="2:1">
          <mat-grid-tile>{{countContacts}}</mat-grid-tile>
          <mat-grid-tile>{{countChats}}</mat-grid-tile>
          <mat-grid-tile>{{countMessages}}</mat-grid-tile>
        </mat-grid-list>
      </div>

      <div class="flex flex-col w-full items-center justify-center">
        <button
          class="mt-3 w-[200px] h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
          (click)="sync()">Sincronizar
        </button>

        <button *ngIf="connected"
          class="mt-3 w-[200px] h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
          (click)="restart()">Reiniciar Canal
        </button>

        <button
          class="mt-3 w-[200px] h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
          (click)="syncContacts()">
          {{ !loading ? 'Sincronizar Contatos' : '' }}
          <mat-spinner class="mr-4" [diameter]="25" [color]="'accent'" mode="indeterminate" *ngIf="loading"></mat-spinner>
        </button>

        <button *ngIf="connected"
          class="mt-3 w-[200px] h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
          (click)="disconnect()">Desconectar Canal
        </button>
      </div>
    </mat-card>
  </div>
</div>
<!-- <div class="flex items-center w-full mt-[30px]">
  <button
    class="h-[45px] px-14 flex cursor-pointer justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-full"
    (click)="close()">Fechar
  </button>
</div> -->